// .logout{
//   text-align: right;
//   position: absolute;
//   right: 0;
// }
.app-bar {
  .MuiPaper-root {
    background-color: #3f51b5 !important;
    color: white;
  }
}
